import React from "react"
import "../styles/styles.scss"
import "../styles/bootstrap.scss"
import { Row, Col, Card } from "react-bootstrap"
import { graphql, Link } from "gatsby"
import { FaExpandAlt } from "react-icons/fa"
import { ModalRoutingContext } from "gatsby-plugin-modal-routing"

import Img from "gatsby-image"
import Head from "../components/head"

export const PortfolioQuery = graphql`
  query Portfolio($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        file {
          name
          pdf_file {
            publicURL
          }
        }
        images {
          image1 {
            image {
              publicURL
              childImageSharp {
                fluid(maxWidth: 526, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
          image2 {
            image {
              publicURL
              childImageSharp {
                fluid(maxWidth: 526, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
          image3 {
            image {
              publicURL
              childImageSharp {
                fluid(maxWidth: 526, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
          image4 {
            image {
              publicURL
              childImageSharp {
                fluid(maxWidth: 526, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
        }
      }
      fields {
        slug
      }
    }
  }
`

const PortfolioTemplate = props => {
  return (
    <>
      <Head title=" Portfolio |" />
      <div className="portfolioTemplate ">
        <Row className="center">
          <Col xs={12} sm={12} md={12} lg={10}>
            <p className="expand">
              <ModalRoutingContext.Consumer>
                {({ modal }) => (
                  <div>
                    {modal ? (
                      <Link
                        to={`/portfolio/${props.data.markdownRemark.fields.slug}`}
                      >
                        <FaExpandAlt />
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </ModalRoutingContext.Consumer>
            </p>
            <h2 style={{ display: "inline" }}>
              {props.data.markdownRemark.frontmatter.title}
            </h2>
            <p className="mutedText">
              {props.data.markdownRemark.frontmatter.date}
            </p>
            <hr className="gradientDash" />
            <div
              className="portfolio-paragraph"
              dangerouslySetInnerHTML={{
                __html: props.data.markdownRemark.html,
              }}
            ></div>
            {props.data.markdownRemark.frontmatter.file &&
              props.data.markdownRemark.frontmatter.file.pdf_file && (
                <a
                  className="link"
                  href={
                    props.data.markdownRemark.frontmatter.file.pdf_file
                      .publicURL
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  {props.data.markdownRemark.frontmatter.file.name}
                </a>
              )}
          </Col>
        </Row>
        <Row className="center">
          <Col xs={12} sm={12} md={12} lg={10} className="col_padding">
            {props.data.markdownRemark.frontmatter.images && (
              <Card>
                <a
                  href={
                    props.data.markdownRemark.frontmatter.images.image1.image
                      .publicURL
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ModalRoutingContext.Consumer>
                    {({ modal }) => (
                      <>
                        {modal ? (
                          <Img
                            className="imgFitPortfolio"
                            fluid={
                              props.data.markdownRemark.frontmatter.images
                                .image1.image.childImageSharp.fluid
                            }
                            alt={
                              props.data.markdownRemark.frontmatter.images
                                .image1.alt
                            }
                          />
                        ) : (
                          <Img
                            className="imgFitPortfolioFull"
                            fluid={
                              props.data.markdownRemark.frontmatter.images
                                .image1.image.childImageSharp.fluid
                            }
                            alt={
                              props.data.markdownRemark.frontmatter.images
                                .image1.alt
                            }
                          />
                        )}
                      </>
                    )}
                  </ModalRoutingContext.Consumer>
                </a>
              </Card>
            )}
          </Col>
          <Col xs={12} sm={12} md={12} lg={10} className="col_padding">
            {props.data.markdownRemark.frontmatter.images && (
              <Card>
                <a
                  href={
                    props.data.markdownRemark.frontmatter.images.image2.image
                      .publicURL
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ModalRoutingContext.Consumer>
                    {({ modal }) => (
                      <>
                        {modal ? (
                          <Img
                            className="imgFitPortfolio"
                            fluid={
                              props.data.markdownRemark.frontmatter.images
                                .image2.image.childImageSharp.fluid
                            }
                            alt={
                              props.data.markdownRemark.frontmatter.images
                                .image2.alt
                            }
                          />
                        ) : (
                          <Img
                            className="imgFitPortfolioFull"
                            fluid={
                              props.data.markdownRemark.frontmatter.images
                                .image2.image.childImageSharp.fluid
                            }
                            alt={
                              props.data.markdownRemark.frontmatter.images
                                .image2.alt
                            }
                          />
                        )}
                      </>
                    )}
                  </ModalRoutingContext.Consumer>
                </a>
              </Card>
            )}
          </Col>
        </Row>
        {props.data.markdownRemark.frontmatter.images &&
          props.data.markdownRemark.frontmatter.images.image3 &&
          props.data.markdownRemark.frontmatter.images.image4 && (
            <Row className="center">
              <Col xs={12} sm={12} md={12} lg={10} className="col_padding">
                {props.data.markdownRemark.frontmatter.images && (
                  <Card>
                    <a
                      href={
                        props.data.markdownRemark.frontmatter.images.image3
                          .image.publicURL
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      <ModalRoutingContext.Consumer>
                        {({ modal }) => (
                          <>
                            {modal ? (
                              <Img
                                className="imgFitPortfolio"
                                fluid={
                                  props.data.markdownRemark.frontmatter.images
                                    .image3.image.childImageSharp.fluid
                                }
                                alt={
                                  props.data.markdownRemark.frontmatter.images
                                    .image3.alt
                                }
                              />
                            ) : (
                              <Img
                                className="imgFitPortfolioFull"
                                fluid={
                                  props.data.markdownRemark.frontmatter.images
                                    .image3.image.childImageSharp.fluid
                                }
                                alt={
                                  props.data.markdownRemark.frontmatter.images
                                    .image3.alt
                                }
                              />
                            )}
                          </>
                        )}
                      </ModalRoutingContext.Consumer>
                    </a>
                  </Card>
                )}
              </Col>
              <Col xs={12} sm={12} md={12} lg={10} className="col_padding">
                {props.data.markdownRemark.frontmatter.images && (
                  <Card>
                    <a
                      href={
                        props.data.markdownRemark.frontmatter.images.image4
                          .image.publicURL
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ModalRoutingContext.Consumer>
                        {({ modal }) => (
                          <>
                            {modal ? (
                              <Img
                                className="imgFitPortfolio"
                                fluid={
                                  props.data.markdownRemark.frontmatter.images
                                    .image4.image.childImageSharp.fluid
                                }
                                alt={
                                  props.data.markdownRemark.frontmatter.images
                                    .image4.alt
                                }
                              />
                            ) : (
                              <Img
                                className="imgFitPortfolioFull"
                                fluid={
                                  props.data.markdownRemark.frontmatter.images
                                    .image4.image.childImageSharp.fluid
                                }
                                alt={
                                  props.data.markdownRemark.frontmatter.images
                                    .image4.alt
                                }
                              />
                            )}
                          </>
                        )}
                      </ModalRoutingContext.Consumer>
                    </a>
                  </Card>
                )}
              </Col>
            </Row>
          )}
      </div>
    </>
  )
}

export default PortfolioTemplate
